<template>
  <NuxtLink
    v-element-visibility="(visible: boolean) => cardIsVisible = visible"
    class="card-container group bg-white font-sans md:aspect-[10/13] flex flex-col text-neutral rounded-lg overflow-hidden text-lg shadow-lg transition-all !text-neutral"
    :class="{
      'mini-card': miniCard
    }"
    :to="to"
    @touchstart="miniCard && navigateTo(to)"
    @mouseover="mouseOverCard = true"
    @mouseleave="mouseOverCard = false"
  >
    <div
      class="relative w-full min-h-[55%] h-[60%] max-h-[220px] mb-2 rounded-br-[0] overflow-hidden"
    >
      <div
        v-if="showSwitch || showFavorite"
        class="absolute top-1 z-[1] flex gap-1"
        :class="{
          'left-1': showFavorite === 'left',
          'right-1': showFavorite !== 'left'
        }"
      >
        <div v-if="showSwitch" class="relative w-8 h-8 pt-0.5 mr-1">
          <AppSwitch
            class="variant-white scale-[0.75] z-[1]"
            :name="`switch-${id}`"
            :model-value="switched"
            :title="
              switched
                ? $t('account.propertyNotificationsEnabled')
                : $t('account.propertyNotificationsDisabled')
            "
            @update:model-value="value => (switched = value)"
          />
        </div>
        <div
          v-if="showFavorite"
          class="w-8 h-8 opacity-70 hover:opacity-100 transition-opacity"
          :class="{
            '!opacity-100': favorite
          }"
        >
          <Icon
            :path="mdiHeart"
            class="z-[1] absolute w-8 h-full p-1 pointer-events-none text-black/70 transition-colors"
            :class="{
              'text-primary/100 drop-shadow-lg/70': favorite
            }"
          />
          <IconButton
            :path="mdiHeartOutline"
            class="z-[1] absolute w-8 h-full drop-shadow-lg rounded-full p-1 cursor-pointer text-white/100 transition-colors"
            @click.stop.prevent="favorite = !favorite"
          />
        </div>
      </div>
      <img
        v-if="!images?.length"
        src="/images/pfs-placeholder.svg"
        loading="lazy"
        :alt="imgAlt || address || 'PFS Realty Group'"
        class="bg-white w-full h-full max-h-[161px] object-contain object-center"
        width="220"
        height="161"
      />
      <img
        v-else-if="images.length === 1 || hideCarousel || !showSplide"
        :src="
          (images[imageIndex || 0] || images[0]).url ||
          '/images/pfs-placeholder.svg'
        "
        :srcset="(images[imageIndex || 0] || images[0]).set"
        loading="lazy"
        :alt="
          (images[imageIndex || 0] || images[0]).alt ||
          address ||
          'PFS Realty Group'
        "
        class="w-full h-full max-h-[161px] object-cover object-center"
        width="220"
        height="161"
        onerror="javascript:this.src='/images/pfs-placeholder.svg'"
      />
      <Splide
        v-else
        ref="splide"
        :options="{
          perPage: 1,
          perMove: 1,
          pagination: false,
          loop: true,
          lazyLoad: 'nearby',
          role: 'group'
        }"
        class="w-full h-full max-h-[161px] object-cover object-center small-arrows"
        :has-track="false"
      >
        <div class="splide__arrows" @click.stop.prevent></div>
        <SplideTrack class="h-full max-h-[161px]">
          <SplideSlide v-for="(image, index) in images" :key="`${index}-slide`">
            <img
              v-if="index === 0 || index === imageIndex"
              :src="image.url"
              :srcset="image.set"
              loading="lazy"
              :alt="image.alt || address || 'PFS Realty Group'"
              class="w-full h-full max-h-[161px] object-cover object-center"
              width="220"
              height="161"
              onerror="javascript:this.src='/images/pfs-placeholder.svg'"
            />
            <img
              v-else
              :data-splide-lazy="image.url"
              :data-splide-lazy-srcset="image.set"
              :alt="image.alt || address || 'PFS Realty Group'"
              class="w-full h-full max-h-[161px] object-cover object-center"
              width="220"
              height="161"
              onerror="javascript:this.src='/images/pfs-placeholder.svg'"
            />
          </SplideSlide>
        </SplideTrack>
      </Splide>
      <div
        class="w-[60px] h-[30px] bg-white pr-px pl-1 pt-1 pb-px pointer-events-none overflow-hidden absolute right-0 bottom-0 rounded-tl"
      >
        <NuxtImg
          class="w-full h-full object-contain object-center"
          :src="`${host}/images/miami-realtors-logo.png`"
          alt="Miami Realtors"
          width="60"
          height="30"
          densities="x1 x2"
        />
      </div>
    </div>
    <p class="px-4 !mb-2 font-bold">{{ $n(price, 'currency', language) }}</p>
    <p class="px-4 !text-sm !mb-0" v-html="info"></p>
    <component
      :is="'h' + (h || 2)"
      class="block px-4 !text-sm !my-0 truncate !font-normal"
    >
      {{ address }}
    </component>
    <component
      :is="'h' + ((h || 2) + 1)"
      class="block px-4 !text-sm !mt-0 !mb-2 truncate !font-normal"
    >
      {{ postalCode }}
    </component>
  </NuxtLink>
</template>

<script setup lang="ts">
import { mdiHeart, mdiHeartOutline } from '@mdi/js';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import { vElementVisibility } from '@vueuse/components';

import type { PropertyCardProps } from '@/types/properties';

const AppSwitch = defineAsyncComponent(
  () => import('@/async-components/App/Switch.vue')
);
const IconButton = defineAsyncComponent(
  () => import('@/async-components/Icon/Button.vue')
);

const { t } = useI18n();

const { language } = useLanguage();

const localePath = useLocalePath();

interface PropertyCardPropsExtended extends PropertyCardProps {
  hideCarousel?: boolean;
  showSwitch?: boolean;
  showFavorite?: boolean | 'left';
  h?: number;
  miniCard?: boolean;
}

const props = defineProps<PropertyCardPropsExtended>();

const host = useHost(true);

const to = computed(() => {
  return props.to ? localePath(`/${t('routes.properties')}/${props.to}`) : '';
});

const favorite = defineModel<boolean>('favorite', {
  default: false
});

const switched = defineModel<boolean>('switched', {
  default: false
});

const splide = ref(null);
const imageIndex = ref(0);

watch(splide, () => {
  if (!splide.value?.splide) {
    return;
  }

  if (imageIndex.value !== splide.value.splide.index) {
    const speed = splide.value.splide.options.speed;
    splide.value.splide.options.speed = 0;
    splide.value.go(imageIndex.value);
    splide.value.splide.options.speed = speed;
  }

  splide.value.splide.on('moved', (newIndex: number) => {
    imageIndex.value = newIndex;
  });
});

const enableSplide = ref(false);

const screenWidth = ref<number | undefined>(undefined);

function setScreenWidth() {
  screenWidth.value = window.innerWidth;
}

const cardIsVisible = ref(false);
const mouseOverCard = ref(false);

const showSplide = computed(() => {
  if (!enableSplide.value) {
    return false;
  }

  if (screenWidth.value < 580) {
    return cardIsVisible.value;
  } else {
    return mouseOverCard.value;
  }
});

onMounted(() => {
  setTimeout(() => (enableSplide.value = true), 500);

  screenWidth.value = window.innerWidth;

  window.addEventListener('resize', setScreenWidth);
});

onUnmounted(() => {
  window.removeEventListener('resize', setScreenWidth);
});
</script>

<style lang="scss">
@import '~/assets/css/splide-theme.scss';
</style>

<style scoped lang="scss">
li {
  margin-bottom: 0 !important;
}

.splide__arrows {
  .splide__arrow--prev {
    left: 0;
  }
}

.card-container:not(.mini-card) {
  @apply sm:min-w-[220px] sm:w-[220px] max-w-[calc(100vw-32px)] min-h-[290px] hover:opacity-90;
}

.card-container.mini-card {
  @apply min-w-[160px] max-w-[200px] min-h-[220px];
  &:hover {
    @apply shadow-xl;
  }
  .image-to-scale {
    transform: none !important;
  }
}
</style>
